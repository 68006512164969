<template>
  <v-container>
    <v-row class="sc-title mt-7" justify="space-between">
      <v-col sm="6" md="8">
        <div>
          <h1>PRE-EVAL CALL</h1>
        </div>
      </v-col>
      <v-col md="2" sm="6">
        <div class="text-right">
          <v-btn
            color="primary"
            @click="
              $router.push({
                name: 'EvaluationDetail',
                params: { id: $route.params.id },
              })
            "
            >Back
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mb-p">
        <h2 class="mb-3">{{ evaluation_info.username }}</h2>
        <p><b>Date:</b> {{ evaluation_info.eval_date }}</p>
        <p><b>Time:</b> {{ evaluation_info.eval_time }}</p>
        <p><b>2</b> {{ evaluation_info.eval_daysout }}</p>
        <p><b>Address:</b> {{ evaluation_info.address }}</p>
        <p><b>City / ST:</b> {{ evaluation_info.city }}</p>
        <p><b>Phone:</b> {{ evaluation_info.phone }}</p>
        <p><b>Email:</b> {{ evaluation_info.email }}</p>
      </v-col>
    </v-row>
    <v-row style="margin-bottom: 100px">
      <v-col cols="12">
        <v-row justify="center">
          <v-col sm="6" md="12">
            <v-textarea
              label="Comments:"
              auto-grow
              outlined
              rows="3"
              row-height="40"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col md="2" sm="6">
            <div>
              <v-btn color="success" dark block>CALL MADE</v-btn>
            </div>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col md="2" sm="6">
            <div>
              <v-btn color="error" dark block>NOT CALLING</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "EvaluationReschedule.vue",
  data() {
    return {
      evaluation_id: this.$route.params.id,
      evaluation_info: {
        username: "JOE CUSTOMER",
        eval_date: "9/1/2020",
        eval_time: "10:00",
        eval_daysout: "Days out from today",
        address: "565 Murlen",
        city: "Overland Park, KS",
        phone: "913-242-1234",
        email: "Jcust@gmail.com",
      },
    };
  },
};
</script>

<style scoped></style>
